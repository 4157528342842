import React, { useContext, useEffect, useState } from 'react';
import { CgProfile } from 'react-icons/cg';
import { FaPeopleGroup } from 'react-icons/fa6';
import { MdHistory, MdLogout, MdOutlinePhone } from 'react-icons/md';
import { PiHouseSimpleBold } from 'react-icons/pi';
import { useLocation, useNavigate } from 'react-router-dom';
import { NewButton, SidebarButton } from '@epcbuilder/lib/components/Buttons';
import { AuthContext } from '@epcbuilder/lib/context/authContext';
import useMediaMaxWidth from '@epcbuilder/lib/hooks/useMediaMaxWidth';
import { ContactModal } from './ContactModal';

enum MenuItems {
  PROPERTIES = 'properties',
  PROFILE = 'profile',
  JOBHISTORY = 'job-history',
  COMPANY = 'company',
}

const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatchLogout } = useContext(AuthContext);
  const [active, setActive] = useState<string>(location.pathname.split('/')[1] as MenuItems);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showSecondarySidebar, setShowSecondarySidebar] = useState(false);
  const [isSmallHeight, setIsSmallHeight] = useState(window.innerHeight < 450);
  const isMobile = useMediaMaxWidth(640);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallHeight(window.innerHeight < 450);
      if (window.innerHeight >= 450) setShowSecondarySidebar(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
      const sidebarElement = document.getElementById('secondary-sidebar');
      const profileIconElement = document.getElementById('sidebar-profile-icon');
      const target = event.target as Node;

      if (!sidebarElement?.contains(target) && !profileIconElement?.contains(target)) {
        setShowSecondarySidebar(false);
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleProfileIconClick = () => setShowSecondarySidebar(!showSecondarySidebar);

  return (
    <>
      <div
        id="sidebar"
        className={`max-w-18 border-neutral bg-color-secondary group fixed left-0 z-10 hidden h-screen w-16 border-r-2 pt-[4rem] shadow drop-shadow-[0px_0px_10px_rgba(38,70,83,0.15)] hover:w-48 sm:block lg:w-48 lg:pt-24`}
      >
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-1 flex-col gap-2 p-2 lg:mt-4 lg:p-4">
            <SidebarButton
              id={MenuItems.PROPERTIES}
              href="/properties"
              icon={<PiHouseSimpleBold size={20} />}
              text="Properties"
              active={active}
              setActive={setActive}
            />
            <SidebarButton
              id={MenuItems.JOBHISTORY}
              href="/job-history"
              icon={<MdHistory size={20} />}
              text="Job History"
              active={active}
              setActive={setActive}
            />
            <SidebarButton
              id={MenuItems.COMPANY}
              href="/company"
              icon={<FaPeopleGroup size={20} />}
              text="Company"
              active={active}
              setActive={setActive}
            />
          </div>
          <div className="flex flex-col gap-2 p-2 lg:p-4">
            {isSmallHeight ? (
              <SidebarButton
                id="profile-icon"
                icon={<CgProfile size={20} />}
                text="Profile"
                onClick={handleProfileIconClick}
              />
            ) : (
              <>
                <button
                  type="button"
                  id="sidebar-contact"
                  className="bg-blue dark:bg-primary mb-1 flex h-12 items-center rounded-xl px-3 text-base drop-shadow-[0px_4px_0px_#9CCEC8] dark:drop-shadow-[0px_4px_0px_#195e55]"
                  onClick={() => setShowContactModal(true)}
                >
                  <MdOutlinePhone size={20} />
                  <div className="ml-4 hidden font-bold group-hover:block lg:block">Contact Us</div>
                </button>
                <SidebarButton
                  id={MenuItems.PROFILE}
                  href="/profile"
                  icon={<CgProfile size={20} />}
                  text="Profile"
                  active={active}
                  setActive={setActive}
                />
                <SidebarButton
                  id="logout"
                  icon={<MdLogout size={20} />}
                  text="Logout"
                  onClick={() => {
                    dispatchLogout();
                    navigate('/');
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      {isSmallHeight && showSecondarySidebar && !isMobile && (
        <div
          id="secondary-sidebar"
          className=" border-neutral bg-color-secondary fixed left-[12rem] z-20 h-screen w-48 border-r-2 pt-[3.5rem] shadow-lg lg:pt-28"
        >
          <div className="flex flex-col gap-2 px-2 py-4">
            <NewButton
              variant="primary"
              id="sidebar-secondary-contact-us"
              icon={<MdOutlinePhone size={20} />}
              text="Contact Us"
              onClick={() => {
                setShowContactModal(true);
                setShowSecondarySidebar(false);
              }}
            />
            <SidebarButton
              id="secondary-profile"
              icon={<CgProfile size={20} />}
              text="Profile"
              onClick={() => {
                handleProfileIconClick();
                navigate('/profile');
                setShowSecondarySidebar(false);
              }}
              showText
            />
            <SidebarButton
              id="secondary-logout"
              icon={<MdLogout size={20} />}
              text="Logout"
              onClick={() => {
                dispatchLogout();
                navigate('/');
                setShowSecondarySidebar(false);
              }}
              showText
            />
          </div>
        </div>
      )}

      {showContactModal && <ContactModal setShowContactModal={setShowContactModal} />}
    </>
  );
};

export default SideBar;
