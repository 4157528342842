import React from 'react';
import { FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import { MdOutlineSearch } from 'react-icons/md';
import useMediaMaxWidth from '../hooks/useMediaMaxWidth';

type SearchFilters = {
  search: string;
};

/** This generic T allows the SearchBar component to be used with forms that have the basic structure
 * of SearchFilters but might also include additional fields.
 * It makes the component more reusable and type-safe when working with forms in React using react-hook-form */

const SearchBar = <T extends SearchFilters>({
  register,
  errors,
}: {
  register: UseFormRegister<T>;
  errors?: FieldErrors<T>;
}) => {
  const isMobile = useMediaMaxWidth(1023);

  const searchError = errors?.search?.message as string | undefined;

  return (
    <div className="relative">
      <input
        {...register('search' as Path<T>)}
        id="search"
        name="search"
        title="Your search query"
        placeholder={isMobile ? 'Search...' : 'Search by address or postcode...'}
        className="bg-color-secondary dark:border-primary placeholder:text-color dark:hover:border-primary border-blue-lighter hover:border-blue-dark focus:border-blue-dark h-12 w-full rounded-[25px] border-2 px-4 outline-none dark:focus:border-[#7ac2b9]"
      />
      <MdOutlineSearch size={24} className="text-color absolute right-6 top-1/2 -translate-y-1/2" />
      {searchError && (
        <div id="search-error" className="ml-2 flex flex-col">
          <p className="text-error text-xs">{searchError}</p>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
