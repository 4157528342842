import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Loading } from '@epcbuilder/lib/components';
import Layout from '@/components/Layout';
import { ProfileForm } from '@/components/profile';
import withAuth from '@/hoc/withAuth';
import useUser from '@/hooks/auth/useUser';
import useUserCompany from '@/hooks/company/useUserCompany';

const ProfilePage = () => {
  const { user } = useUser();
  const { company } = useUserCompany();

  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="" />
      </Helmet>
      <Layout title="EPC Builder Profile">
        <div className="flex flex-col p-4 sm:p-8">
          <div id="profile-heading" className="flex flex-row items-center gap-8">
            <h1>Profile</h1>
          </div>
          <Container>
            <div className="flex justify-center">
              <div className="border-neutral w-full max-w-xl rounded-xl border-2 p-4">
                {!user ? <Loading /> : <ProfileForm user={user} company={company} />}
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    </>
  );
};

export default withAuth(ProfilePage);
